<template>
  <div id="data-list-list-view" class="data-list-container" v-if="company && users">
    <license-agreement v-show="showLicenseAgreement"></license-agreement>
    <pricing-plans v-show="showPriceList"></pricing-plans>
    <link rel="stylesheet" type="text/css" media="all" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.min.css" />
    <vs-prompt
      :title="$t('modals.deleteTeamMember')"
      @cancel="onCloseModal"
      @accept="deleteData"
      @close="onCloseModal"
      color="danger"
      :cancel-text="$t('vue.cancel')"
      :accept-text="$t('vue.delete')"
      :active.sync="showConfirmDelete"
    >
      <div class="con-exemple-prompt" v-if="selectedUser">
        <p>
          <strong>{{ selectedUser.displayName ? selectedUser.displayName : `${selectedUser.firstname} ${selectedUser.lastname}` }}</strong>
        </p>
        {{ $t('modals.deleteTeamMemberQuestion') }}
      </div>
    </vs-prompt>

    <vx-card class="mb-4" :title="$t('routes.users')" title-color="primary" subtitle-color="danger">
      <p class="mb-3">{{ $t('vue.companyTeamTabDescription') }}</p>
      <div slot="footer">
        <vs-row v-if="SHOW_AVAILABLE_REMAINING_USERS" vs-justify="flex-start">
          <div>
            <span class="mr-2">
              {{ $t('vue.totalAvailableUsers') }} : <b>{{ totalAvailableUsers }}</b>
            </span>
            <span class="ml-2">
              {{ $t('vue.totalRemainingUsers') }} : <b>{{ totalRemainingUsers }}</b>
            </span>
          </div>
        </vs-row>
        <vs-row v-if="!activeUserInfo.role || activeUserInfo.role === 'admin'" vs-justify="flex-end">
          <vs-button
            color="primary"
            type="filled"
            icon-size="24px"
            icon="add"
            @click.stop="inviteUser"
            :disabled="DISABLE_ADDING_NEW_TEAM_MEMBER"
            class="flex"
            >{{ $t('vue.inviteUser') }}</vs-button
          >
        </vs-row>
      </div>
    </vx-card>

    <vx-card>
      <vs-table
        v-show="!showLicenseAgreement && !showPriceList"
        ref="table"
        v-model="selected"
        pagination
        :max-items="itemsPerPage"
        search
        :data="sortedViewedUsers"
      >
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-end">
          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
            <div
              class="p-4 border border-solid d-theme-border-grey-light d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              style="border-radius: 5px"
            >
              <span class="mr-2">
                {{ firstRecordNumber }} - {{ lastRecordNumber }} {{ $t('vue.of') }}
                {{ queriedItems }}
              </span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="itemsPerPage = 3">
                <span>3</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 4">
                <span>4</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 10">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 15">
                <span>15</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 20">
                <span>20</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <template slot="thead">
          <vs-th sort-key="user"></vs-th>
          <vs-th sort-key="email">{{ $t('vue.email') }}</vs-th>
          <vs-th sort-key="firstname">{{ $t('vue.firstname') }}</vs-th>
          <vs-th sort-key="lastname">{{ $t('vue.lastname') }}</vs-th>
          <vs-th
            v-if="!activeUserInfo.role || activeUserInfo.role === 'admin' || (company.isCallCenter && ['agent', 'supervisor'].includes(activeUserInfo.role))"
            sort-key="lastname"
            >User Role</vs-th
          >
          <vs-th v-if="(!activeUserInfo.role || activeUserInfo.role === 'admin') && company.isCallCenter">User supervisor</vs-th>
          <vs-th> {{ $t('vue.activeConsultant') }} </vs-th>
          <vs-th style="width: 300px">{{ $t('vue.action') }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <Avatar class="mr-1" size="38px" :photoURL="tr.photoURL" :name="`${tr.firstname} ${tr.lastname}`" />
              </vs-td>

              <vs-td>
                <p class="product-name font-medium truncate">{{ tr.email }}</p>
              </vs-td>

              <vs-td>
                <p class="product-name font-medium truncate">{{ tr.firstname }}</p>
              </vs-td>

              <vs-td>
                <p class="product-name font-medium truncate">{{ tr.lastname }}</p>
              </vs-td>
              <vs-td v-if="!activeUserInfo.role || activeUserInfo.role === 'admin' || activeUserInfo.impersonated">
                <p class="product-name font-medium truncate userRole" v-if="tr.id !== activeUserInfo.uid || activeUserInfo.impersonated">
                  <select
                    @change="updateRole(tr.id, $event)"
                    :disabled="(disable || tr.emailPending || tr.id === company.createdBy ? true : false) && !activeUserInfo.impersonated"
                  >
                    <option v-if="activeUserInfo.impersonated" :selected="tr.userRole === 'vega-analyze'" value="vega-analyze">
                      {{ $t('vue.vegaAnalyze') }}
                    </option>
                    <option :selected="tr.userRole === 'admin'" value="admin">{{ $t('vue.admin') }}</option>
                    <option :selected="tr.userRole === 'agent'" value="agent">{{ $t('vue.agent') }}</option>
                    <option :selected="tr.userRole === 'view-only'" value="view-only">{{ $t('vue.viewOnly') }}</option>
                    <option :selected="tr.userRole === 'supervisor'" v-if="company.isCallCenter" value="supervisor">{{ $t('vue.supervisor') }}</option>
                  </select>
                </p>
              </vs-td>

              <vs-td v-if="(!activeUserInfo.role || activeUserInfo.role === 'admin') && company.isCallCenter">
                <p
                  v-if="tr.id !== activeUserInfo.uid && tr.userRole === 'agent' && supervisorsList.length && !tr.emailPending"
                  class="product-name font-medium truncate userRole"
                >
                  <select v-model="tr.supervisorId" @change="setSupervisor($event, tr.id)">
                    <option v-if="!tr.supervisorId" disabled selected value />
                    <option v-for="supervisor in supervisorsList" :key="supervisor.id" :value="supervisor.id">{{ supervisor.displayName }}</option>
                  </select>
                </p>
              </vs-td>

              <vs-td v-if="company.isCallCenter && ['agent', 'supervisor'].includes(activeUserInfo.role)">
                <p class="product-name font-medium truncate userRole">{{ $t(`vue.${tr.userRole}`) }}</p>
              </vs-td>

              <vs-td>
                <div @click="onToggleActiveConsultant(tr)">
                  <toggle-switch-icon :opacity="tr.userRole === 'admin' ? 1 : 0.4" :enabled="tr.isActiveConsultant" :width="40" :height="40" />
                </div>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <div class="flex-column justify-center items-center">
                  <div class="flex justify-center items-center">
                    <vs-button
                      v-if="
                        tr.id !== activeUserInfo.uid &&
                        (tr.emailPending || ((typeof tr.provider === 'undefined' || tr.provider === 'email') && !tr.hasSetPassword))
                      "
                      :disabled="disable || disableResendEmailInvitation ? true : false"
                      color="warning"
                      class="table-button"
                      type="border"
                      size="medium"
                      icon-pack="feather"
                      icon="icon-rotate-cw"
                      @click.stop="resendEmailInvitation(tr)"
                    >
                      {{ $t('vue.resendEmailInvitation') }}
                    </vs-button>

                    <vs-button
                      v-if="tr.id === activeUserInfo.uid"
                      color="primary"
                      class="table-button"
                      type="border"
                      size="medium"
                      icon-pack="feather"
                      icon="icon-edit"
                      to="/user-settings"
                    >
                      {{ $t('vue.myAccount') }}
                    </vs-button>
                    <vx-tooltip :text="$t('vue.impersonate')" position="top">
                      <vs-button
                        v-if="activeUserInfo.uid !== tr.id && activeUserInfo.impersonated && !tr.emailPending"
                        color="primary"
                        type="border"
                        size="medium"
                        icon-pack="feather"
                        icon="icon-user"
                        @click.stop="impersonate(tr.id)"
                      ></vs-button>
                    </vx-tooltip>
                    <div>
                      <vs-chip v-if="tr.id === company.createdBy && tr.id !== activeUserInfo.uid" class="ml-auto">owner</vs-chip>
                      <vx-tooltip
                        v-if="tr.id !== activeUserInfo.uid && tr.id !== company.createdBy && (!activeUserInfo.role || activeUserInfo.role === 'admin')"
                        :text="$t('vue.delete')"
                        class="inline-flex"
                        position="top"
                      >
                        <vs-button color="danger" type="border" size="medium" icon-pack="feather" icon="icon-trash" @click.stop="() => onDeleteData(tr)">
                        </vs-button>
                      </vx-tooltip>
                    </div>
                  </div>
                  <vs-chip v-if="tr.inviteStatus && tr.emailPending" class="mt-2" :color="deliveryStatusColor(tr.inviteStatus)">
                    {{ $t(tr.inviteStatus) }}
                  </vs-chip>
                </div>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
      <vs-prompt
        v-if="activePrompt"
        :cancel-text="$t('vue.cancel')"
        :accept-text="$t('vue.inviteUser')"
        :title="$t('vue.inviteUser')"
        @accept="createUserEmail"
        :active.sync="activePrompt"
        :is-valid="validateForm"
      >
        <div class="con-exemple-prompt">
          <span>{{ $t('vue.enterFirstname') }}</span>
          <vs-input
            :placeholder="$t('vue.firstname')"
            v-model="inviteData.firstname"
            v-validate="'required'"
            data-vv-validate-on="blur"
            :name="$t('vue.firstname')"
            class="mt-3 w-full"
          />
          <div class="text-danger text-sm">{{ errors.first($t('vue.firstname')) }}</div>
          <span>{{ $t('vue.enterLastname') }}</span>
          <vs-input
            :placeholder="$t('vue.lastname')"
            v-model="inviteData.lastname"
            v-validate="'required'"
            data-vv-validate-on="blur"
            :name="$t('vue.lastname')"
            class="mt-3 w-full"
          />
          <div class="text-danger text-sm">{{ errors.first($t('vue.lastname')) }}</div>
          <span>{{ $t('vue.enterEmailAddress') }}</span>
          <vs-input
            :placeholder="$t('vue.email')"
            v-model="inviteData.email"
            v-validate="'required|email'"
            data-vv-validate-on="blur"
            :name="$t('inputs.email')"
            type="email"
            class="mt-3 w-full"
          />
          <div class="text-danger text-sm">{{ errors.first($t('inputs.email')) }}</div>
        </div>
      </vs-prompt>
      <!-- LICENSE PROMPT NOT SHOWN ANYMORE -->
      <!-- <vs-popup class="popup" :title="$t('vue.inviteUser')" :active.sync="licensePrompt">
        <div class="con-exemple-prompt text-center">
          <p class="trial text-left mb-5" v-html="$t('html.licenseAgreement')"></p>
          <vs-button :disabled="DISABLE_ADDING_NEW_TEAM_MEMBER" @click="createUserEmail" class="w-full mt-8">{{ $t('vue.orderNow') }}</vs-button>
        </div>
      </vs-popup> -->
    </vx-card>
  </div>
</template>

<script>
import 'firebase/functions'
// eslint-disable-next-line
import firebase from 'firebase/app'
import moment from 'moment'
import { mapGetters, mapMutations } from 'vuex'
import _ from 'lodash'
import { paddle } from '@/mixins/paddle'

import LicenseAgreement from './pages/register/LicenseAgreement'
import PricingPlans from './pages/plans/PricingPlans.vue'

import Avatar from '@/components/Avatar'
import ToggleSwitchIcon from '@/components/icons/ToggleSwitchIcon.vue'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default {
  mixins: [paddle],
  components: {
    LicenseAgreement,
    PricingPlans,
    Avatar,
    ToggleSwitchIcon
  },
  data() {
    return {
      MAX_USERS_FREE_PLAN: 5,
      selected: [],
      activePrompt: false,
      licensePrompt: false,
      // products: [],
      itemsPerPage: 5,
      isMounted: false,
      inviteData: {},
      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
      notificationsEnabled: false,
      showLicenseAgreement: false,
      showPriceList: false,
      disable: false,
      showConfirmDelete: false,
      selectedUser: null,
      disableResendEmailInvitation: false
    }
  },
  computed: {
    ...mapGetters({
      campaigns: 'campaigns',
      activeUserInfo: 'activeUser',
      allUsers: 'users',
      company: 'company',
      subscriptionPlans: 'subscription_plans'
    }),

    HAS_CANCELLED_PADDLE_SUBSCRIPTION() {
      return (
        this.company &&
        Boolean(this.company.subscriptionId) &&
        Boolean(this.company.subscriptionPlanId) &&
        this.company.paymentStatus === 'deleted' &&
        this.company.expiryDate &&
        dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
      )
    },

    DISABLE_ADDING_NEW_TEAM_MEMBER() {
      if (this.HAS_PADDLE_SUBSCRIPTION) {
        return false
      }
      if (this.company && this.company.planType === 'partner') {
        return false
      }

      if (this.IS_ON_TRIAL) {
        if (this.totalRemainingUsers === 0) {
          return true
        }
        return false
      }
      const disableAddingNewTeamMember = !this.HAS_SUBSCRIPTION ? true : this.totalRemainingUsers === 0 || this.disable
      return disableAddingNewTeamMember
    },

    SHOW_AVAILABLE_REMAINING_USERS() {
      if (this.HAS_PADDLE_SUBSCRIPTION) {
        return false
      }
      return this.HAS_SUBSCRIPTION || this.IS_ON_TRIAL
    },

    IS_ON_TRIAL() {
      if (!this.company) return false
      return Boolean(this.company && this.company.paymentStatus === 'on-trial' && this.company.planType !== 'partner')
    },

    HAS_SUBSCRIPTION() {
      return (
        this.HAS_MOLLIE_SUBSCRIPTION ||
        this.HAS_INVOICE_SUBSCRIPTION ||
        this.HAS_PADDLE_SUBSCRIPTION ||
        this.HAS_APPSUMO_SUBSCRIPTION ||
        this.HAS_SHOPIFY_SUBSCRIPTION ||
        this.HAS_FREE_SUBSCRIPTION
      )
    },

    HAS_FREE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.subscriptionPlanId && this.company.subscriptionPlanId.indexOf('FREE') > -1 && this.company.paymentStatus === 'subscription')
    },

    HAS_SHOPIFY_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company && this.company.isShopifyAccount)
    },

    HAS_APPSUMO_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company && this.company.isAppSumoAccount)
    },

    HAS_INVOICE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.currentInvoiceId)
    },

    HAS_MOLLIE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.mollieCustomerId && this.company.mollieSubscriptionId)
    },

    HAS_PADDLE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.subscriptionId && !this.company.mollieSubscriptionId && this.company.paymentStatus === 'subscription')
    },

    totalUsersForPlan() {
      return this.IS_ON_TRIAL
        ? this.company.totalUsersForPlan || this.MAX_USERS_FREE_PLAN
        : this.company.totalUsersForPlan || this.getTotalUsersForSubscriptionPlan(this.company.subscriptionPlanId)
    },

    totalAvailableUsers() {
      const totalLicenses = this.company && typeof this.company.totalLicenses !== 'undefined' ? this.company.totalLicenses : 1
      return totalLicenses * this.totalUsersForPlan
    },
    totalRemainingUsers() {
      const totalActiveUsers = this.users.length
      const remainingUsers = this.totalAvailableUsers - totalActiveUsers
      return remainingUsers >= 0 ? remainingUsers : 0
    },
    users() {
      return this.allUsers.filter((x) => x.company === this.activeUserInfo.company)
    },
    validateForm() {
      return (
        !this.errors.any() &&
        typeof this.inviteData.firstname !== 'undefined' &&
        this.inviteData.firstname !== '' &&
        typeof this.inviteData.lastname !== 'undefined' &&
        this.inviteData.lastname !== '' &&
        typeof this.inviteData.email !== 'undefined' &&
        this.inviteData.email !== ''
      )
    },
    firstRecordNumber() {
      let currentPage = 1
      if (this.$refs.table && this.$refs.table.currentx) {
        currentPage = this.$refs.table.currentx
      }
      if (this.queriedItems === 0) {
        return 0
      }

      const x = (currentPage - 1) * this.itemsPerPage
      return x + 1
    },
    lastRecordNumber() {
      const recordNumber = this.firstRecordNumber + this.itemsPerPage - 1
      return recordNumber < this.queriedItems ? recordNumber : this.queriedItems
    },
    currentPage() {
      if (this.$refs.table) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.users.length
    },
    locale() {
      return this.$i18n.locale
    },

    supervisorsList() {
      return this.users.filter((user) => user.userRole === 'supervisor')
    },

    userListViewedForAgentsAndSupervisor() {
      const copyUsers = _.cloneDeep(this.users)

      const userListViewedForAgents = copyUsers.filter((user) => {
        if (this.activeUserInfo.uid === user.id) {
          return user
        } else if (['agent', 'supervisor'].includes(user.userRole)) {
          const isFoundSupervisorId = copyUsers.findIndex((user) => user.supervisorId) > -1

          if (this.activeUserInfo.uid === user.supervisorId || this.activeUserInfo.supervisorId === user.id) {
            return user
          } else if (!isFoundSupervisorId && !user.supervisorId && user.userRole === 'agent') {
            return user
          }
        }

        return false
      })

      return userListViewedForAgents
    },

    sortedViewedUsers() {
      if (!this.activeUserInfo) {
        return []
      }

      const isAdmin =
        !this.activeUserInfo.role ||
        this.activeUserInfo.role === 'admin' ||
        (!this.activeUserInfo.isSupervisor && !this.activeUserInfo.isAgent) ||
        this.activeUserInfo.superadmin

      const result = isAdmin ? _.cloneDeep(this.users) : this.userListViewedForAgentsAndSupervisor

      return result
        .sort((a, b) => {
          if (b.sort !== a.sort) {
            return b.sort - a.sort
          }
          return a.created - b.created
        })
        .reverse()
    }
  },
  methods: {
    ...mapMutations({
      addUpdateUser: 'ADD_UPDATE_USER'
    }),
    async impersonate(uid) {
      const vm = this
      vm.$vs.loading({ type: 'point' })
      try {
        const getUserCustomToken = vm.$functions.httpsCallable('getUserCustomToken')
        const result = await getUserCustomToken({ uid, impersonate: true, loggedInUser: { uid: vm.activeUserInfo.ipuid, name: vm.activeUserInfo.ipname } })
        if (result.data && result.data.status === true) {
          const token = result.data.token
          await firebase.auth().signInWithCustomToken(token)
          location.href = '/'
        } else {
          const message = vm.$i18n.t(`error.${result.data.messageCode}`)
          vm.$vs.notify({
            time: 4000,
            title: vm.$i18n.t('vue.error'),
            text: message,
            color: 'danger',
            iconPack: 'feather',
            position: 'bottom-right',
            icon: 'icon-alert-circle'
          })
          vm.$vs.loading.close()
        }
      } catch (error) {
        const message = error.message
        vm.$vs.notify({
          time: 4000,
          title: vm.$i18n.t('vue.error'),
          text: message,
          color: 'danger',
          iconPack: 'feather',
          position: 'bottom-right',
          icon: 'icon-alert-circle'
        })
        vm.$vs.loading.close()
      }
    },
    deliveryStatusColor(emailDeliveryStatus) {
      switch (emailDeliveryStatus.toLowerCase()) {
        case 'emaildelivery.sending':
          return '#0D47A1'
        case 'emaildelivery.send':
          return '#1E88E5'
        case 'emaildelivery.renderingfailure':
          return '#B71C1C'
        case 'emaildelivery.reject':
          return '#880E4F'
        case 'emaildelivery.delivery':
          return '#1B5E20'
        case 'emaildelivery.bounce':
          return '#E65100'
        case 'emaildelivery.complaint':
          return '#BF360C'
        case 'emaildelivery.deliverydelay':
          return '#F9A825'
        case 'emaildelivery.subscription':
          return '#424242'
        case 'emaildelivery.open':
          return '#388E3C'
        case 'emaildelivery.click':
          return '#5E35B1'
        default:
          return '#616161'
      }
    },

    async onToggleActiveConsultant(user) {
      if (user.userRole !== 'admin') return
      user.isActiveConsultant = !user.isActiveConsultant
      await this.$db.collection('users').doc(user.id).set({ isActiveConsultant: user.isActiveConsultant }, { merge: true })
    },
    async updateRole(id, event) {
      await this.$vs.loading()

      const value = event.target.value
      const copyUsers = _.cloneDeep(this.users)

      if (value !== 'supervisor') {
        const usersRef = await this.$db.collection('users').where('company', '==', this.activeUserInfo.company).where('supervisorId', '==', id).get()

        await Promise.all(
          usersRef.docs.map(async (userRef) => {
            return await userRef.ref.set({ supervisorId: null }, { merge: true })
          })
        )
      } else if (value === 'supervisor' && copyUsers.findIndex((user) => user.id === id && !!user.supervisorId) > -1) {
        const usersRef = await this.$db.collection('users').doc(id)

        await usersRef.set({ supervisorId: null }, { merge: true })
      }

      const setClaim = this.$functions.httpsCallable('setAdminClaim')

      const response = await setClaim({
        userId: id,
        role: value
      })
      if (response.data && response.data.status === 'error') {
        this.showErrorMessage(response.data.message)
      }
      this.$vs.loading.close()
    },
    showErrorMessage(error) {
      this.$vs.notify({
        title: this.$i18n.t('vue.error'),
        text: this.$i18n.t(error),
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
      this.$vs.loading.close()
    },
    displayLicenseAgreement() {
      this.showLicenseAgreement = true
      this.licensePrompt = false
    },
    displayPriceList() {
      this.showPriceList = true
      this.licensePrompt = false
    },
    getActionCodeSettings(id, email) {
      const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be whitelisted in the Firebase Console.
        url: `${window.location.protocol}//${window.location.host}/user-onboarding?userId=${id}&email=${email}`,
        // This must be true.
        handleCodeInApp: true
      }
      return actionCodeSettings
    },

    async sendSignInLinkToEmail(user) {
      try {
        this.disableResendEmailInvitation = true
        await this.$vs.loading()
        const actionCodeSettings = this.getActionCodeSettings(user.id, user.email)
        const sendUserInvitation = this.$functions.httpsCallable('inviteUser')
        await sendUserInvitation({
          email: user.email,
          firstname: user.firstname,
          lastname: user.lastname,
          actionCodeSettings,
          locale: this.$i18n.locale,
          contextUserId: this.activeUserInfo.uid,
          userId: user.id
        })
        this.$vs.loading.close()
      } catch (error) {
        // eslint-disable-next-line
        console.log(error.message)
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: error.message,
          color: 'danger',
          iconPack: 'feather',
          position: 'bottom-right',
          icon: 'icon-alert-circle'
        })
      }

      setTimeout(() => {
        this.disableResendEmailInvitation = false
      }, 30000)
    },

    async resendEmailInvitation(user) {
      if (!this.company) {
        return
      }
      this.disableResendEmailInvitation = true

      if (this.company && this.company.isAppSumoAccount) {
        const notification = {
          locale: this.$i18n.locale,
          companyId: this.activeUserInfo.company,
          agentId: user.id,
          agentEmail: user.email,
          appsumoUUID: this.company.appsumoUUID,
          source: 'new-account-user',
          type: 'appsumo-agent-activation-email'
        }
        await this.$db.collection('email-notifications').add(notification)
      } else {
        this.sendSignInLinkToEmail(user)
      }
    },

    inviteUser() {
      const vm = this
      vm.activePrompt = true
      const paLicenseAgreement = document.getElementById('paLicenseAgreement')
      if (paLicenseAgreement) {
        paLicenseAgreement.addEventListener('click', vm.displayLicenseAgreement)
      }
      const paPriceList = document.getElementById('paPriceList')
      if (paPriceList) {
        paPriceList.addEventListener('click', vm.displayPriceList)
      }
    },

    createUserOrShowLicence() {
      if (this.HAS_PADDLE_SUBSCRIPTION) {
        this.showLicensePopup()
      } else {
        this.createUserEmail()
      }
    },

    showLicensePopup() {
      this.activePrompt = false
      this.licensePrompt = true
    },
    async createUserEmail() {
      const vm = this
      if (!this.validateForm) return

      await vm.$vs.loading()
      // await firebase.functions().useFunctionsEmulator('http://localhost:5001')
      const checkEmailExists = this.$functions.httpsCallable('checkEmailExists')
      const result = await checkEmailExists({
        email: this.inviteData.email
      })

      if (result.data) {
        this.$vs.notify({
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('vue.userAlreadyExists'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        this.licensePrompt = false
        this.activePrompt = true
        await vm.$vs.loading.close()
      } else {
        const userData = {
          created: new Date(),
          createdBy: this.activeUserInfo.uid,
          email: this.inviteData.email,
          identifier: this.inviteData.email,
          firstname: this.inviteData.firstname,
          lastname: this.inviteData.lastname,
          displayName: `${this.inviteData.firstname} ${this.inviteData.lastname}`,
          company: this.activeUserInfo.company,
          emailPending: true,
          hasSetPassword: false,
          isAgent: true,
          language: this.$languagesSuppported.includes(navigator.language.slice(0, 2)) ? navigator.language.slice(0, 2) : this.$defaultLanguage
        }
        const userRef = await this.$db.collection('users').add(userData)
        if (userRef && userRef.id) {
          this.addUpdateUser({
            id: userRef.id,
            firstname: this.inviteData.firstname,
            lastname: this.inviteData.lastname,
            email: this.inviteData.email,
            emailPending: true,
            isSynced: false,
            userRole: 'agent',
            displayName: `${this.inviteData.firstname} ${this.inviteData.lastname}`,
            name: `${this.inviteData.firstname} ${this.inviteData.lastname}`,
            company: this.activeUserInfo.company,
            inviteStatus: 'emaildelivery.sending'
          })

          if (this.company && this.company.isAppSumoAccount) {
            const notification = {
              locale: this.$i18n.locale,
              companyId: this.activeUserInfo.company,
              agentId: userRef.id,
              agentEmail: this.inviteData.email,
              appsumoUUID: this.company.appsumoUUID,
              source: 'new-account-user',
              type: 'appsumo-agent-activation-email'
            }
            await this.$db.collection('email-notifications').add(notification)
          } else {
            userData.id = userRef.id
            await this.sendSignInLinkToEmail(userData)
          }
        }
        await vm.$vs.loading.close()

        this.inviteData.email = ''
        this.inviteData.firstname = ''
        this.inviteData.lastname = ''

        if (this.HAS_PADDLE_SUBSCRIPTION) {
          //await firebase.functions().useFunctionsEmulator('http://localhost:5001')
          const updateSubscription = this.$functions.httpsCallable('updateSubscription')
          await updateSubscription({
            companyId: this.activeUserInfo.company
          })
          const companyDoc = await this.$db.collection('company').doc(this.activeUserInfo.company)
          companyDoc.set({ licenses: firebase.firestore.FieldValue.increment(1) }, { merge: true })
        }
      }
    },
    addNewData() {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    onCloseModal() {
      this.showConfirmDelete = false
      this.selectedUser = null
    },
    onDeleteData(user) {
      this.showConfirmDelete = true
      this.selectedUser = user
    },
    async deleteData() {
      await this.$vs.loading()

      const { id } = this.selectedUser
      /* Check if user is part of any campaigns */
      const campaigns = this.campaigns
        .filter((x) => x.recipients.map((x) => x.id).includes(id))
        .map((y) => {
          return {
            id: y.id,
            recipients: y.recipients,
            name: y.name
          }
        })

      if (campaigns) {
        for (const campaign of campaigns) {
          const recipients = campaign.recipients.filter((x) => x.id !== id)
          if (recipients.length === 0) {
            await this.$db.collection('campaigns').doc(campaign.id).delete()
          } else {
            await this.$db.collection('campaigns').doc(campaign.id).set({ recipients }, { merge: true })
          }
        }
      }

      await this.deleteAccount(id)
      await this.$firebase.database().ref(`/users/${this.activeUserInfo.company}/${id}`).set(null)

      const companyDoc = await this.$db.collection('company').doc(this.activeUserInfo.company)
      companyDoc.set({ licenses: firebase.firestore.FieldValue.increment(-1) }, { merge: true })
      if (this.HAS_PADDLE_SUBSCRIPTION) {
        //await firebase.functions().useFunctionsEmulator('http://localhost:5001')
        const updateSubscription = this.$functions.httpsCallable('updateSubscription')
        await updateSubscription({
          companyId: this.activeUserInfo.company
        })
      }
      this.showConfirmDelete = false
      this.selectedUser = null

      await this.$vs.loading.close()
    },
    async deleteAccount(id) {
      const deleteAccount = this.$functions.httpsCallable('deleteAccount')
      const result = await deleteAccount({ uid: id, isAuthOnly: true })
      if (result.data.status !== true) {
        const message = this.$i18n.t(`error.${result.data.messageCode}`)
        this.$vs.notify({
          time: 4000,
          title: this.$i18n.t('vue.error'),
          text: message,
          color: 'danger',
          iconPack: 'feather',
          position: 'bottom-right',
          icon: 'icon-alert-circle'
        })
      }
    },
    editData(data) {
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },

    async setSupervisor($event, userId) {
      await this.$vs.loading()

      const supervisorId = $event.target.value

      const userRef = await this.$db.collection('users').doc(userId)

      await userRef.set({ supervisorId }, { merge: true })

      await this.$vs.loading.close()
    }
  },
  async created() {
    const vm = this
    vm.$serverBus.$on('close-license-agreement', () => {
      vm.licensePrompt = true
      vm.showLicenseAgreement = false
    })
    vm.$serverBus.$on('close-price-list', () => {
      vm.licensePrompt = true
      vm.showPriceList = false
    })

    await vm.$vs.loading()

    if (!vm.activeUserInfo && !vm.activeUserInfo.company) {
      await vm.$vs.loading.close()
      return
    }

    const companyDoc = await this.$db.collection('company').doc(vm.activeUserInfo.company)
    const companyRef = await companyDoc.get()
    const company = companyRef.data()

    if (company) {
      if (
        company.paymentStatus &&
        (!company.expiryDate || (company.expiryDate && moment(company.expiryDate).format('MM/DD/yyyy') < moment().format('MM/DD/yyyy'))) &&
        (company.paymentStatus === 'expired' || company.paymentStatus === 'deleted')
      ) {
        this.disable = true
      }

      if (!company.licenses) {
        const usersRef = await this.$db.collection('users').where('company', '==', vm.activeUserInfo.company).where('isActive', '==', true).get()
        const licenses = usersRef.docs.length

        companyDoc.set({ licenses }, { merge: true })
      }
    }

    await vm.$vs.loading.close()
  },
  mounted() {
    this.isMounted = true
  },

  beforeDestroy() {
    this.$serverBus.$off('close-license-agreement')
    this.$serverBus.$off('close-price-list')
  }
}
</script>

<style lang="scss">
a {
  cursor: pointer;
}

#data-list-list-view {
  .con-vs-popup .vs-popup {
    width: 290px !important;
  }
  .includeIcon {
    display: initial;
    margin-left: 5px;
  }
  .table-button * {
    display: inline;
  }

  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: capitalize;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
.userRole select {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  box-shadow: none;
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  font-weight: 400;
  width: 130px;
  line-height: 1.5;
  letter-spacing: 0.01rem;
}
</style>
